const dictionary = {
  general: {
    logo: 'Sobrancelhas Design',
    abbreviationLogo: 'SD',
    copy: 'Copiar',
    notification: {
      error: 'Não foi possível carregar os dados.',
      pageNotFound: 'Página não encontrada',
      forbidden: 'Você não possui acesso a esta área',
    },
    action: 'Ação',
    addNew: 'Adicionar',
    edit: 'Editar',
    alter: 'Alterar',
    note: 'Nota',
    update: 'Atualizar',
    back: 'Voltar',
  },
  auth: {
    login: 'Entrar',
    logout: 'Sair',
    connectionFail: 'Não foi possível realizar o login, tente mais tarde.',
    pageTitle: 'Loja do Franqueado',
    welcome: 'Bem-vindo à Loja do Franqueado.',
    instruction: 'Clique no botão abaixo para acessar.',
  },
  menu: {
    apollo: 'Novo Avant',
    reports: {
      title: 'Relatórios',
      billing: 'Relatório de faturamento',
    },
    midiaKit: {
      title: 'Mídia Kit',
      preInauguration: 'Pré-Inauguração',
      inaugurationDay: 'Inauguração e dia a dia',
      gifts: 'Brindes',
      campaignsPromotions: 'Campanhas e Promoções',
      socialNetworks: 'Redes Sociais (Posts)',
    },
    corporateMaterial: {
      title: 'Material corporativo',
      openFranchisee: 'Antes de abrir sua franquia',
      layoutFranchisee: 'Layout de franquia',
      operationFranchisee: 'Operação de franquia',
      bulletin: 'Comunicados',
      technicalCenter: 'Centro técnico',
    },
    videos: {
      title: 'Vídeos',
      eyebrows: 'Sobrancelhas',
      eyes: 'Olhos',
      face: 'Rosto',
      lips: 'Boca',
      oaccs: 'Acessórios',
    },
    store: {
      title: 'Loja',
      kits: 'Início',
      cart: 'Carrinho',
      orders: 'Meus pedidos',
      ordersSingle: 'Página de pedido',
      checkout: 'Finalize sua compra',
      orderplaced: '',
    },
    admin: {
      kits: 'Admin - Início',
      orders: 'Admin - Pedidos',
      promotions: 'Admin - Promoções',
      financial: 'Admin - Financeiro',
      wallet: {
        title: 'Admin - Bonificação',
        points: 'Lançar Bonificação',
        bonus: 'Bonificação',
        products: 'Cadastro produtos',
      },
      store: {
        title: 'Listagem de Kits',
        new: 'Criação de Kit',
        edit: 'Edição de Kit',
        orders: 'Pedidos',
        order: 'Página do pedido',
        promotions: 'Promoções',
        financial: 'Financeiro',
        movements: 'Lançamentos de Bonificação',
        products: 'Produtos',
        newPromotion: 'Adicionar Promoção',
        updatePromotion: 'Editar Promoção',
      },
    },
  },
  pageHeader: {
    search: 'Buscar',
    filter: 'Filtrar',
  },
  home: {
    title: 'Página inicial',
  },
  avant: {
    logo: 'Avant',
    new: {
      title: 'Novo Avant',
      description: 'Esse é o novo Avant',
    },
    download: 'Download',
    license: 'Licença',
    copy: 'Licença copiada!',
    notification: {
      noLicense: {
        title: 'Licenças disponíveis',
        text: 'Não encontramos nenhuma licença disponível para o seu usuário. Certifique-se de ter feito o login com o e-mail da unidade e tente novamente mais tarde.\r\n\r\nEm caso de dúvidas, entre em contato com o Suporte através do e-mail <a href="mailto:novo_avant@gsd.com.br">novo_avant@gsd.com.br</a>.'
      },
    },
  },
  download: {
    description: 'Descrição',
    files: 'Aquivos',
    copy: 'Link copiado!',
  },
  reports: {
    filters: {
      states: 'Todos os estados',
      button: 'Filtrar',
    },
    charts: {
      typeServiceQuantity: 'Tipo de Serviço (Quantidade)',
      typeServiceValue: 'Tipo de Serviço (Valor)',
      sdMakeUpQuantity: 'SD MAKE•UP (Quantidade)',
      sdMakeUpValue: 'SD MAKE•UP (Valor)',
      utilizationCombo: 'Aproveitamento: Combo (Quantidade)',
      utilizationEpilation: 'Aproveitamento: Epilação (Quantidade)',
      utilizationSdRelax: 'Aproveitamento: SD Relax (Quantidade)',
      utilizationSdMakeUp: 'Aproveitamento: SD MAKE•UP (Quantidade)',
    },
    table: {
      title: 'Resumo de serviços',
      quantity: 'Quantidade',
      value: 'Valor',
    },
  },
  store: {
    add: 'Comprar',
    remove: 'Remover',
    view: 'Visualizar',
    unavailable: 'Indisponível',
    addNew: 'Adicionar',
    minPrice: 'a partir de',
    notifyAvailable: 'Avise-me quando disponível',
    types: {
      1: 'Insumo',
      2: 'Makeup',
    },
    emptyData: 'Nenhum kit encontrado',
    notification: {
      list: {
        error: 'Não foi possível atualizar o kit.',
      },
      notifyMe: {
        success: 'Você será avisado quando este produto estiver disponível.',
        error: 'Não foi possível agendar o aviso',
      }
    },
    search: {
      kits: 'Filtrar por kit',
      products: 'Buscar por produto',
    },
    blocked: {
      type: 'O carrinho já contém kits de “{type}”. Somente é possível adicionar kits de mesmo tipo',
      origin: 'O carrinho já contém kits que vêm de “{origin}”. Somente é possível adicionar kits de mesma origem',
      stock: 'Esgotado',
      exclusive: 'Kits exclusivos precisam ser únicos no carrinho',
      promotion: 'Kits promocionais só podem ser adicionados com outros kits promocionais',
    },
    alert: {
      title: 'Atenção!',
      message: 'Encontramos uma pendência no cadastro da sua unidade.<br>Em caso de dúvidas, por favor, entre em contato com o setor Financeiro, através do e-mail <a href="mailto:{email}">{email}</a>.',
      retry: 'Tentar novamente',
      email: 'financeiro@gsd.com.br',
    },
    notFound: 'Nenhum produto encontrado para o filtro selecionado.',
    points: {
      calculation: '| de {value} para {previousBalance}',
      model: {
        empty: 'Não existe nenhuma transação',
        title: 'Movimentações',
      },
      status: {
        received: 'Recebido',
        exchange: 'Troca',
        reversal: 'Estorno',
        canceled: 'Cancelado',
        pending: 'Pendente',
        deposit: 'Depósito',
      },
    },
  },
  orders: {
    order: 'Pedido',
    title: 'Meus pedidos',
    course: 'Treinamento',
    kit: 'Kit',
    trackingNumber: 'Código de rastreio',
    noTrackingNumber: 'Indisponível',
    shippingMethod: 'Frete',
    quantity: 'Quantidade',
    comments: 'Observações',
    price: 'Valor',
    discount: 'Desconto',
    subtotal: 'Subtotal',
    total: 'Total',
    unit: 'Unidade',
    paymentInfo: 'Pagamento',
    productsSubtotal: 'Produtos',
    insurance: 'Seguro',
    goBack: 'Voltar',
    table: {
      title: 'Lista de produtos',
      orderedAt: 'Data do pedido',
      updatedAt: 'Data de atualização',
      order: '#',
      status: 'Status',
      externalId: 'Focco ID',
      total: 'Total',
      subtotal: 'Subtotal',
      product: 'Nome',
      items: 'Itens',
      unit: 'Unidade',
      distribution: 'CD',
      nf: 'NF',
      discount: 'Desconto',
      goldPoints: 'Pontos Gold',
      silverPoints: 'Pontos Silver',
      erpId: 'Focco Id'
    },
    modal: {
      title: 'Aviso',
      continue: 'Continuar',
      cancel: 'Cancelar',
      message: 'Você já possui produtos no Carinho! Ao comprar novamente, todos os itens atuais serão removidos. Deseja prosseguir?'
    },
    rePurchase: 'Comprar novamente',
    cancelOrder: 'Cancelar pedido',
    confirmCancel: 'Clique para confirmar',
    download: 'Baixar etiquetas',
    unavailableDownload: 'Etiquetas indisponíveis',
    noBarcode: 'O pedido não possui etiquetas',
    notification: {
      error: 'Não foi possível carregar o pedido',
    },
    alert: {
      title: 'Pré-reserva',
      message: 'Você precisa preencher as informações de todos os participantes para confirmar sua reserva.',
    },
    search: {
      labels: {
        title: 'Parâmetros da busca:',
        button: 'Buscar',
        order: 'Pedido',
        unit: 'Unidade',
        dateRange: 'Periodo',
        status: 'Status',
        distribution: 'Centro de destribuição',
        erpId: 'Focco Id'
      },
      order: 'Buscar por Nº do pedido',
      unit: 'Buscar por unidade',
      distribution: 'Todos os centros',
      erpId: 'Buscar por Nº do FoccoErp'
    },
    warning: {
      title: 'Aviso',
      message: 'Este pedido ainda não foi finalizado.'
    }
  },
  billing: {
    title: 'Informações de Pagamento',
    status: {
      expired: 'Atrasado',
      paid: 'Pago',
      open: 'Aberto',
      empty: '-',
    },
    table: {
      installment: 'Parcela',
      expiration: 'Vencimento',
      status: 'Status',
      price: 'Valor',
      download: 'Baixar',
      availableDownload: 'Baixar boleto',
      unavailableDownload: 'Disponível em breve',
    },
  },
  status: {
    error: 'Erro',
    canceled: 'Cancelado',
    cart: 'Carrinho',
    processing: 'Processando...',
    payment_pending: 'Aguardando pagamento',
    paid: 'Pago',
    preparing: 'Preparando...',
    prepared: 'Preparado',
    sent: 'Enviado',
    avant_received: 'Recebido no Avant',
  },
  dateRanges: {
    today: 'Hoje',
    thisMonth: 'Este mês',
    lastMonth: 'Último mês',
    sevenDaysAgo: 'Últimos 7 dias',
    thirtyDaysAgo: 'Últimos 30 dias',
  },
  checkout: {
    title: 'Resumo do Pedido',
    update: 'Atualizar',
    firstIntroMessage: 'Pronto para fazer seu pedido?',
    secondIntroMessage: 'Vamos conferir se está tudo certo.',
    titleCarriers: 'Opções de frete',
    titlePaymentMethods: 'Opções de pagamento',
    commentsLabel: 'Observações',
    shippinfInfoLabel: 'Informações para retirar no local',
    purchase: 'Finalizar compra',
    orderReview: {
      subtotal: 'Subtotal',
      insurance: 'Seguro',
      shipping: 'Frete',
      discount: 'Desconto: {discount}',
      totalDiscount: 'Total de descontos',
      total: 'Total a pagar',
    },
    notice: 'Verifique se as opções de frete e pagamento foram selecionadas para finalizar a compra.',
    shippingInfoNotice: 'Quando você escolhe retirar no local, você precisa nos fornecer algumas informações importantes: Razão Social e CNPJ da transportadora.',
    deliveryAddress: 'Endereço de entrega:',
    shippingLoading: 'Calculando frete',
    notification: {
      success: 'Pedido finalizado com sucesso',
      error: 'Não foi possível finalizar o pedido.',
    },
    requiredBaseKit: 'Incluir Kit: {kitRequired}',
    monthlyInstallments: 'Opção',
    orderPlaced: {
      title: 'Obrigado!',
      subtitle: 'Pedido realizado com sucesso.',
      received: 'Recebemos as informações do seu pedido. Os boletos estão sendo gerados e ficarão disponíveis em breve na loja. Fique atento ao seu e-mail.',
      notice: '',
      continueShopping: 'Continuar comprando',
      viewOrders: 'Visualizar pedidos',
    },
  },
  admin: {
    store: {
      notification: {
        create: {
          success: 'Kit criado com sucesso.',
          error: 'Não foi possível criar o kit.',
        },
        update: {
          success: 'Kit atualizado com sucesso.',
          error: 'Não foi possível atualizar o kit.',
        },
        labels: {
          success: 'Download concluído.',
          error: 'Não foi possível baixar as etiquetas.',
        },
        order: {
          cancel: 'Pedido cancelado com sucesso',
          errorCancel: 'Não foi possível cancelar o pedido',
          nf: 'NF salva com sucesso',
          errorNf: 'Não foi possivel salvar a NF',
        },
      },
      active: 'Ativo',
      inactive: 'Inativo',
      status: 'Status',
      balanceInsufficient: 'Estoque insuficiente',
      create: {
        form: {
          button: 'Salvar',
          name: 'Nome do kit',
          image: 'Imagem do kit',
          price: 'Preço mínimo',
          type: 'Tipo do kit',
          types: {
            makeup: 'Makeup',
            input: 'Insumo',
          },
          custom: 'Customizável',
          active: 'Ativo',
          discount: 'Desconto (%)',
          isExclusive: 'Exclusivo',
          isGloballyAvailable: 'Disponível para todos',
          fixedItemsQuantity: 'Quantidade fixa de itens',
          units: {
            list: 'Unidades',
            search: 'Buscar unidades',
            period: 'Período',
            empty: 'Nenhuma unidade adicionada',
          },
          regions: {
            search: 'Buscar regiões',
          },
          products: {
            list: 'Produtos',
            search: 'Buscar produtos',
            exception: '*Selecione o tipo do kit antes de adicionar os produtos',
            empty: 'Nenhum produto adicionado',
            min: 'Quantidade mínima',
            max: 'Quantidade máxima',
          },
        },
      },
    },
  },
  cart: {
    title: 'Carrinho de compras',
    table: {
      name: 'Nome',
      quantity: 'Quantidade',
      unitValue: 'Preço unitário',
      subtotal: 'Subtotal',
      total: 'Total',
      button: 'Próximo',
      continueShopping: 'Continuar comprando',
      discount: 'Desconto',
      goldPoints: 'Pontos de ouro',
      silverPoints: 'Pontos de prata',
    },
    info: {
      title: 'Vencimento do boleto',
      description: 'Não serão aceitos pagamentos dos boletos após o vencimento. Fique atento à data impressa no boleto.',
    },
    notification: {
      remove: {
        error: 'Não foi possível remover o kit do carrinho',
      },
      add: {
        error: 'Não foi possível adicionar o kit ao carrinho',
        product: 'Por favor, revise os produtos indicados abaixo.',
      },
      update: {
        error: 'Não foi possível atualizar o kit do carrinho',
      },
    },
  },
  table: {
    of: 'de',
    items: 'itens',
    perPage: ' por página',
    columnSearch: {
      search: 'Buscar',
      reset: 'Limpar',
    },
  },
  modal: {
    kitProduct: {
      minimum: 'Preço mínimo',
      kitValue: 'Valor do kit',
      addToCart: 'Adicionar ao carrinho',
      updateCart: 'Atualizar carrinho',
      pointsAvailable: 'Pontos disponiveis',
      fixedItemsQuantity: 'Produtos Necessários',
    },
  },
  wallets: {
    addNew: 'Adicionar',
    table: {
      wallet: 'Carteira',
      description: 'Descrição',
      unit: 'Unidade',
      type: 'Tipo',
      points: 'Pontos',
      noAccess: 'Desculpe, usuário não tem permissão para acessar essa página.',
      empty: 'Sem dados',
      name: 'Nome',
      isActive: 'Ativo?',
      product: 'Produto',
      reward: 'Premiação',
      rewardDescription: 'Bonificação de pontos do tipo {type}',
      quantity: 'Quantidade',
      dateCreate: 'Data de Criação',
    },
    titleModal: 'Depósitar pontos',
    form: {
      title: 'Título',
      product: 'Produto',
      productName: 'Nome do produto',
      unit: 'Unidade',
      type: 'Tipo',
      quantity: 'Quantidade',
      points: 'Pontos',
      description: 'Descrição',
      button: 'Enviar',
      addNew: 'Adicionar',
      message: {
        unit: 'Obrigatório selecionar uma unidade!',
        type: 'Obrigatório selecionar um tipo de carteira!',
        product: 'Obrigatório selecionar um produto!',
        productName: 'Obrigatório informar o nome do produto!',
        tooltip: 'Selecione o produto disponível para troca de pontos, habilitado pelo FOCCO.',
        points: 'Obrigatório ter pelo menos um ponto!',
        quantity: 'Obrigatório ter pelo uma quantidade!',
        description: 'Obrigatório ter uma descrição sobre esse depósito!',
      },
      placeholder: {
        unit: 'Selecione uma unidade',
        product: 'Selecione um produto',
        type: 'Selecione o tipo de carteira',
      },
    },
    notification: {
      error: 'Não foi possível carregar os pontos.',
      create: {
        success: 'Depósito realizado com sucesso.',
        error: 'Não foi possível realizar o depósito.',
      },
      product: {
        create: {
          success: 'Produto cadastrado com sucesso.',
          error: 'Erro ao cadastrar produto.',
        },
        update: {
          success: 'Produto atualizado com sucesso.',
          error: 'Erro ao atualizar produto.',
        },
      }
    },
  },
  promotions: {
    addNew: 'Adicionar',
    table: {
      promotion: 'Promoção',
      discount: 'Desconto',
      startsAt: 'Início',
      finishesAt: 'Término',
    },
    form: {
      title: 'Título',
      discount: 'Desconto',
      startsAt: 'Início',
      finishesAt: 'Término',
      button: 'Enviar',
      period: 'Período',
      active: 'Ativo',
      description: 'Descrição',
      addNew: 'Adicionar',
      rules: 'Regras',
    },
    rules: {
      type: 'Tipo',
      operator: 'Operador',
      value: 'Valor',
    },
    types: {
      kits: 'Kits',
      payments: 'Formas de Pagamentos',
      shipping: 'Frete',
      subtotal: 'Subtotal',
      cart: 'Carrinho',
      unit: 'Unidade',
      region: 'Região',
    },
    operators: {
      equalTo: 'Igual a (==)',
      lessThan: 'Menor que (<)',
      lessThanOrEqual: 'Menor ou igual a (<=)',
      greaterThan: 'Maior que (>)',
      greaterThanOrEqual: 'Maior ou igual a (>=)',
      contain: 'Contém',
      notContain: 'Não contém'
    },
    notification: {
      create: {
        success: 'Promoção criada com sucesso.',
        error: 'Não foi possível criar a promoção.',
      },
      update: {
        success: 'Promoção atualizada com sucesso.',
        error: 'Não foi possível atualizar a promoção.',
      },
    }
  },
  financial: {
    addDefaulter: 'Adicionar Inadimplente',
    table: {
      unit: 'Unidade',
      editUser: 'Usuário Editado',
      createdAt: 'Criado',
      updatedAt: 'Atualizado',
    },
    pleaseSelectedUnit: 'Por favor! Selecione a unidade.',
    selectedUnit: 'Selecione a unidade',
    createDefaulter: 'Adicionar Inadimplente',
    editDefaulter: 'Editar Inadimplente',
    isActiveDefaulter: {
      true: 'Inadimplente',
      false: 'Liberado'
    },
    addNoteCustomer: 'Adicione uma nota desse cliente',
    addNote: 'Adicione uma nota',
    viewNoteFrom: 'Visualizar nota de',
    notification: {
      create: {
        success: 'Inadimplente adicionado com sucesso.'
      }
    }
  },
}

export default {
  locale: 'pt-BR',
  label: 'Português (Brasil)',
  country: 'BR',
  currency: 'BRL',
  dictionary,
}
